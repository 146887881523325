import axios from "axios";

const LOCAL_BACKEND =
  process.env.REACT_APP_LOCAL_BACKEND || "http://localhost:5010/api";
// const PROD_BACKEND = process.env.REACT_APP_PROD_BACKEND;

const api = axios.create({
  baseURL: LOCAL_BACKEND,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (request) => {
    console.log("Starting Request", request);
    request.headers.authorization = `Bearer ${sessionStorage.getItem(
      "authToken"
    )}`;
    return request;
  },
  function (error) {
    console.log("REQUEST ERROR", error);
  }
);

//뉴스앱 Beare 중복 적힘 수정

api.interceptors.response.use(
  (response) => {
    console.log("Response:", response);
    return response;
  },
  function (error) {
    error = error.response.data;
    console.log("RESPONSE ERROR", error);
    return Promise.reject(error);
  }
);

export default api;
